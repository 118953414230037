import React from "react"
import {
  Container,
  Button,
  Col,
  Media,
  Image,
  Row,
  Form,
} from "react-bootstrap"
import TopMenu from "../components/Layout/TopMenu"
import { LoremIpsum } from "react-lorem-ipsum"
import Footer from "../components/Layout/Footer"

const Portal = () => (
  <Container fluid className="home-container p-0">
    <TopMenu></TopMenu>
    <Container fluid className="home-slider py-xl-6">
          <Container fluid="xl" className="state-page-container d-flex flex-column ">
          <Row> 
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
              <Container>
              <p className="title-text">Portal</p>
                <h1 className="text-center py-0">
                  Borrowing, tracking & paying made easy.
                </h1>
                <LoremIpsum avgWordsPerSentence={6} avgSentencesPerParagraph={6} />
                <ul>
                  <li>View your balance</li>
                  <li>Make withdraws</li>
                  <li>Make payment</li>
                  <li>Notifications</li>
                  <li>Tips</li>
                </ul>

                <Button variant="primary" type="submit" className="w-100">
                  CTA
                </Button>
              </Container>
                
              </Col>
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                
              </Col>
        </Row>
        </Container>
        </Container>

        <Container fluid className="firstcont py-xl-6">
        <Container fluid="xl" className="state-page-container d-flex flex-column ">
        <Row>
              
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                <Container className="mt-5">
                  <LoremIpsum avgWordsPerSentence={6} avgSentencesPerParagraph={6} />

                  <Media>
                    <Image
                      className="px-2"
                      src="https://picsum.photos/80/80"
                      roundedCircle
                    />
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Image
                      className="px-2"
                      src="https://picsum.photos/80/80"
                      roundedCircle
                    />
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Image
                      className="px-2"
                      src="https://picsum.photos/80/80"
                      roundedCircle
                    />
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                </Container>
                
              </Col>
              <Col lg={{ order: "first" }} md={{ order: "last" }} className="pl-xl-4">
                
              </Col>
        </Row>
        
          </Container>
        </Container>

        <Container fluid className="home-slider py-xl-6">
          <Container fluid="xl" className="state-page-container d-flex flex-column ">
          <Row>
              
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
              <Container className="mt-5">
                <h2>Portal Feature Title</h2>
                  <LoremIpsum avgWordsPerSentence={6} avgSentencesPerParagraph={6} />

                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                </Container>
                
              </Col>
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                
              </Col>
        </Row>
        </Container>
        </Container>

        <Container fluid className="firstcont py-xl-6">
        <Container fluid="xl" className="state-page-container d-flex flex-column ">
        <Row>
              
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                <Container className="mt-5">
                <h2>Portal Feature Title</h2>
                  <LoremIpsum avgWordsPerSentence={6} avgSentencesPerParagraph={6} />

                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                </Container>
                
              </Col>
              <Col lg={{ order: "first" }} md={{ order: "last" }} className="pl-xl-4">
                
              </Col>
        </Row>
        
          </Container>
        </Container>
    

    
    <Footer></Footer>
  </Container>
)

export default Portal
